<template>
    <div id="page-digital-comms-template">
        <app-loader v-if="loading" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'digital-comms-templates' }">Back to Digital Comms Templates</router-link>

                    <div class="header-info">
                        <h1 class="name">Edit Template <span v-if="template.Description" >"{{ template.Description }}"</span></h1>
                    </div>
                    <app-tabs v-model="tab" :tabs="tabs" :dropdown-on-tablet="true"></app-tabs>
                </div>
            </div>

            <router-view v-if="is_template_loaded"></router-view>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appLoader from '@/components/app-loader'
import appTabs from '@/components/app-tabs'

export default {
    components: {
        appLoader,
        appTabs,
    },

    data() {
        return {
            template: {},

            tabs: [
                {
                    id: 'general',
                    name: 'digital-comms-template-general',
                    title: 'General',
                },
                {
                    id: 'email',
                    name: 'digital-comms-template-email',
                    title: 'Email',
                },
                {
                    id: 'sms',
                    name: 'digital-comms-template-sms',
                    title: 'SMS',
                },
                {
                    id: 'advanced',
                    name: 'digital-comms-template-advanced',
                    title: 'Advanced',
                },
            ],

            loading: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.loadDigitalCommsTemplateByUUID()
        },

        loadDigitalCommsTemplateByUUID() {
            this.loading = true

            this.$store.dispatch('api_notification/GetNotificationTemplateByUUID', { SPID: this.current_spid, UUID: this.uuid }).then(template => {
                this.template = template

                this.loading = false
            }).catch(error => {
                console.log('Error getCachedNotificationByUUID', this.uuid)
                console.error(error)

                this.$router.push({ name: 'digital-comms-templates', replace: true })
            })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        uuid() {
            return this.$route.params.uuid
        },

        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.name == tab.name || this.$route.name == tab.alias)

                if (!tab) {
                    this.tab = { name: 'digital-comms-template-email', replace: true };
                }

                return tab;
            },

            set(tab) {
                this.$router.push({
                    name: tab.name,
                    replace: true
                });
            }
        },

        is_template_loaded() {
            return this.template && Object.keys(this.template).length > 0
        },
    },
}
</script>

<style lang="scss">
#page-digital-comms-template {
    margin-top: 24px;
    padding-bottom: 80px;

    .app-tabs {
        margin-bottom: 34px;
    }

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        margin-bottom: 40px;

        .name {
            font-size: 56px;
            line-height: 62px;
            font-weight: bold;
        }
    }
}

@media (max-width: $tablet-size) {
    #page-digital-comms-template {
        .header-info {
            width: 100%;
            margin-bottom: 48px;

            .name {
                font-size: 48px;
                line-height: 56px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-digital-comms-template {
        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;

            .name {
                font-size: 32px;
                line-height: 40px;
            }
        }

        .app-tabs {
            margin-bottom: 16px;
        }
    }
}
</style>